import React from "react"
import { Link } from "gatsby"
import "./style/index.scss"
import { GatsbyImage } from "gatsby-plugin-image"

const HeroNews = ({ data, read, currentCat, currentAuthor }) => {
  const category = currentCat
  console.log(currentAuthor,'currentAuthor')
  const style = {
    borderColor: category?.color,
    color: category?.color ? category?.color : "#EE4A4E",
  }
  
  return (
    <div className="main-hero">
      <h1>
        {data?.title}
      </h1>
      <p>
        <Link to={`/news/`}>{'News'}</Link> / {data?.title}
      </p>
      <div className="post-info">
        <div>
          <Link
              style={style}
              to={`/news/category/${data?.category
              .trim()
              .toLowerCase()
              .replace(/ /gi, "-")
              .replace(/[`~!@#$%^&*()_|+\=?;‘:'’",<>]/gi, "")}/`}
          >
          {data?.category}
          </Link>
          <h2 className="m-read sw-paragraph-4 sw-font-weight-xs">
            {read} MIN READ
          </h2>
        </div>
        <div> 
          By
          <GatsbyImage 
            image={currentAuthor?.thumbnail?.childImageSharp?.gatsbyImageData} 
            alt="" 
          /> 
          <Link
            id="author-name"
            to={`/news/author/${currentAuthor?.name
              .trim()
              .toLowerCase()
              .replace(/ /gi, "-")
              .replace(/[`~!@#$%^&*()_|+\=?;‘:'’",<>]/gi, "")}/`}
          >
            {currentAuthor?.name}
          </Link>
          {data?.date}
        </div>
      </div>
    </div>
  )
}

export default HeroNews
